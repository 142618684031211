import React, { useState, useEffect } from "react"
import {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
} from "styled-components"
import { useStore } from "./hooks/store"

export const theme = {
  red: [null, null, "rgb(184,0,0)", "#e20000", null, null, null],
  headingColor: "#e20000",
  linkColor: "#e20000",
  preambleColor: "#646464",
  textColor: "#363636",
  textPageBackgroundColor: "#F9DFDD",
  textPageContentBackgroundColor: "#FBEBEA",
  activeControlColor: "#D90000",
  inactiveControlColor: "#666",
  inactiveControlBgColor: "#909090",
  headingFont: '"Baloo 2", "Source Sans Pro", sans-serif',
  secondaryFont: '"Source Sans Pro", sans-serif',
  rtlFont: '"Noto Sans", sans-serif',
  roomColors: {
    default: {
      base: "#E20000",
      lighter: "#FF0000",
      darker: "#B80000"
    },
    1: {
      base: "#E20000",
      lighter: "#FF0000",
      darker: "#B80000"
    },
    2: {
      base: "rgb(255,204,0)",
      lighter: "#FFE880",
      darker: "#D5B200"
    },
    3: {
      base: "#00AA00",
      lighter: "#72C772",
      darker: "#368D2B"
    },
    4: {
      base: "#FF7800",
      lighter: "#FFBB80",
      darker: "#CA630F"
    },
    5: {
      base: "#0099AF",
      lighter: "#A3E0E4",
      darker: "#66CCD2"
    },
    6: {
      base: "#0064AA",
      lighter: "#80B1D4",
      darker: "#00528B"
    },
    7: {
      base: "#3A3A3A",
      lighter: "#808080",
      darker: "#000"
    },
    8: {
      base: "#A064B4",
      lighter: "#CFB1D9",
      darker: "#7D538D"
    },
  },
  shadows: {
    flatter: "0 0.2rem 0.3rem rgba(0, 0, 0, 0.2)",
    flat: "0 0.37rem 0.5rem rgba(0, 0, 0, 0.2)",
    normal: "0 0.65rem 0.7rem rgba(0, 0, 0, 0.2)",
    high: "0 1rem 1rem rgba(0, 0, 0, 0.2)",
    hover: "",
    pressed: "",
    inset: "inset 0 0.1875rem 0 rgba(255, 255, 255, 0.25)",

    filter: {
      flat: "0 0.3rem 0.3rem rgba(0, 0, 0, 0.35)",
      normal: "0 0.5rem 0.3rem rgba(0, 0, 0, 0.4)",
      high: "0 .8rem .4rem rgba(0, 0, 0, 0.4)",
    }
  },
  animations: {
    transitionBase: `transition: transform .3s ease-in-out, box-shadow .3s ease-in-out, filter .3s ease-in-out;`,
  }

}

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  ${props => props.tabbed && `
    button:not(:focus),
    a:not(:focus),
    .room-content-scroller .item > *:not(:focus-within),
    [class*="RoomMock__Content"] ul li:not(:focus-within),
    .cookie-settings label:not(:focus-within) {
      filter: saturate(0.2);
    }

    *:focus:not([tabindex="-1"]),
    *:focus-visible,
    .cookie-settings label:focus-within {
      box-shadow:
        0 0 1px 3px orange,
        0 0 3px 5px darkblue,
        0 0 6px 10px rgba(0, 0, 0, 0.4);
    }

    svg a:focus:not([tabindex="-1"]),
    svg a:focus-visible,
    [role="button"]:focus-within {
      outline: -webkit-focus-ring-color auto 1px;
      outline: 3px dashed orange;
    }
  `}

  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0s !important;
      transition: none !important;
    }
  }

  ${props => props.noAnimation && `
    *,
    *::before,
    *::after {
      animation-duration: 0s !important;
      transition: none !important;
    }
  `}

  /* Reset margin set by Browser Update */
  html {
    margin-top: 0 !important;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-text-size-adjust: 100%;
  }

  body {
    font-family: ${theme.secondaryFont};
    color: #363636;
    font-size: 100%;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

  .structural {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
  }

  button,
  textarea,
  input {
    font-family: ${theme.secondaryFont};
  }

  button {
    cursor: pointer;
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height:100%;
  }

  h1 {
    font-family: ${theme.headingFont};
    margin-top: 1rem;
    margin-bottom: 0;
    line-height: 125%;
    font-size: 2rem;
  }

  h2 {
    font-size: 1.25rem;
    margin-top: 1.5rem;
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.125rem;
    margin-top: 1.5rem;
    margin-bottom: 0;
    font-weight: 600;
  }

  h2 + p, h3 + p {
    margin-top: 0;
  }

  a {
    color: #E20000;
  }

  a:hover,
  a:focus {
    color: #000;
    text-decoration: none;
  }

  small {
    font-size: 0.875rem;
    line-height: 1.25;
  }

  strong,
  b {
    font-weight: 700;
  }

  article ul {
    padding-left: 1.125rem;
  }

  .WatsonAssistantChatHost {
    display: none;
  }
`

export const ThemeProvider = ({ children, ...props }) => {
  const [store] = useStore()
  const [tabbed, setTabbed] = useState(false)

  const handleMouseDown = () => {
    setTabbed(false)
    document.removeEventListener("mousedown", handleMouseDown)
  }

  const handleKeyDown = (e) => {
    const code = e.keyCode ? e.keyCode : e.which

    if (code === 9) {
      setTabbed(true)
      document.addEventListener("mousedown", handleMouseDown)
    }
  }

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.addEventListener("keydown", handleKeyDown)

      return () => {
        document.removeEventListener("keydown", handleKeyDown)
        document.removeEventListener("mousedown", handleMouseDown)
      }
    }
  }, [])

  return (
    <StyledComponentsThemeProvider {...props}>
      <GlobalStyle noAnimation={!store.animationsEnabled} tabbed={tabbed} />
      {children}
    </StyledComponentsThemeProvider>
  )
}
